@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=M+PLUS+Rounded+1c&display=swap');


.timeline-container {
    position: relative;

  }

  
  .timeline-content {
    padding: 0px 35px;
    position: relative;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
 

  }

ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: baseline;
}


.title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

p{
    font-family: 'Inter', sans-serif;
    font-size: 1.2rem;
}

.selected-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    background-color: #f3f3f3;
    border-radius: 10px;
    border: 1px solid #dbdbdb;
}



.blur-effect {
    transition: filter 0.30s ease-in-out;
    filter: blur(15px);
}

.selected-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0px 30px;

}

.selected-description{
    padding: 0px 30px;
}

a{
    text-decoration: none;
    outline: none;
}

.container{
    display: flex;
    justify-content: center;
    flex-direction: row;
}

@media (max-width: 768px) {
    .container{
        flex-direction: column;
        width: 100%;
        /* background-color: red; */
    }

    .selected-container{
        width: 100%;
        /* background-color: blue; */
    }

    p{
        font-size: 10pt;
    }

    h1{
        font-size: 1.5rem;
    }
}

