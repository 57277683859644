*,
*:after,
*:before {
	box-sizing: border-box;
}

body {
	display: flex;
	place-items: center;
	justify-content: center;
	min-height: 100svh;
	font-family: 'SF Pro Text', 'SF Pro Icons', 'AOS Icons', 'Helvetica Neue',
		Helvetica, Arial, sans-serif, system-ui;
	margin: 0;
	padding: 0.5rem;
}

ul {
  display: flex; 
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  flex-wrap: wrap;
  color: color-mix(in lch, canvasText 50%, canvas);
  transition: color 4s;
  touch-action: none;
}



li a {
  display: inline-block;

  color: currentColor;
  text-decoration: none;
}

:is(a, button) {
  outline-color: color-mix(in lch, canvasText, transparent 50%);
}


a:is(:focus-visible, :hover),
ul:has(a:target:is(:focus-visible, :hover))::after {
	color: canvasText;
}

[data-no-anchor] ul:has(li:nth-of-type(1) a:is(:hover, :focus-visible)) {
	--item-active-y: var(--item-1-y);
	--item-active-x: var(--item-1-x);
	--item-active-width: var(--item-1-width);
	--item-active-height: var(--item-1-height);
}
[data-no-anchor] ul:has(li:nth-of-type(2) a:is(:hover, :focus-visible)) {
	--item-active-y: var(--item-2-y);
	--item-active-x: var(--item-2-x);
	--item-active-width: var(--item-2-width);
	--item-active-height: var(--item-2-height);
}
[data-no-anchor] ul:has(li:nth-of-type(3) a:is(:hover, :focus-visible)) {
	--item-active-y: var(--item-3-y);
	--item-active-x: var(--item-3-x);
	--item-active-width: var(--item-3-width);
	--item-active-height: var(--item-3-height);
}
[data-no-anchor] ul:has(li:nth-of-type(4) a:is(:hover, :focus-visible)) {
	--item-active-y: var(--item-4-y);
	--item-active-x: var(--item-4-x);
	--item-active-width: var(--item-4-width);
	--item-active-height: var(--item-4-height);
}
[data-no-anchor] ul:has(li:nth-of-type(5) a:is(:hover, :focus-visible)) {
	--item-active-y: var(--item-5-y);
	--item-active-x: var(--item-5-x);
	--item-active-width: var(--item-5-width);
	--item-active-height: var(--item-5-height);
}
[data-no-anchor] ul:has(li:nth-of-type(6) a:is(:hover, :focus-visible)) {
	--item-active-y: var(--item-6-y);
	--item-active-x: var(--item-6-x);
	--item-active-width: var(--item-6-width);
	--item-active-height: var(--item-6-height);
}

[data-no-anchor] ul:has(li:nth-of-type(1) a:target) {
	--target-y: var(--item-1-y);
	--target-x: var(--item-1-x);
	--target-width: var(--item-1-width);
	--target-height: var(--item-1-height);
}
[data-no-anchor] ul:has(li:nth-of-type(2) a:target) {
	--target-y: var(--item-2-y);
	--target-x: var(--item-2-x);
	--target-width: var(--item-2-width);
	--target-height: var(--item-2-height);
}
[data-no-anchor] ul:has(li:nth-of-type(3) a:target) {
	--target-y: var(--item-3-y);
	--target-x: var(--item-3-x);
	--target-width: var(--item-3-width);
	--target-height: var(--item-3-height);
}
[data-no-anchor] ul:has(li:nth-of-type(4) a:target) {
	--target-y: var(--item-4-y);
	--target-x: var(--item-4-x);
	--target-width: var(--item-4-width);
	--target-height: var(--item-4-height);
}
[data-no-anchor] ul:has(li:nth-of-type(5) a:target) {
	--target-y: var(--item-5-y);
	--target-x: var(--item-5-x);
	--target-width: var(--item-5-width);
	--target-height: var(--item-5-height);
}
[data-no-anchor] ul:has(li:nth-of-type(6) a:target) {
	--target-y: var(--item-6-y);
	--target-x: var(--item-6-x);
	--target-width: var(--item-6-width);
	--target-height: var(--item-6-height);
}

ul:has(a:is(:focus-visible, :hover)) {
  --intent: 1;
}

ul::before,
ul::after {
	--transition: 0.2s;
  background: #f3f3f3;
	content: "";
	position: fixed;
	pointer-events: none;
	top: calc(var(--item-active-y) * 1px);
	left: calc(var(--item-active-x) * 1px);
	height: calc(var(--item-active-height) * 1px);
	width: calc(var(--item-active-width) * 1px);
	opacity: var(--intent, 0);
	z-index: -1;
	border-radius: 10px;
	/* background: color-mix(in lch, canvasText, canvas 85%); */

    /* background: #ececec; */
    background: transparent;
    border: 3px solid #f3f3f3;
	transition:
		all var(--transition),
		top var(--transition),
		left var(--transition),
	  height var(--transition),
	  opacity var(--transition),
	  color var(--transition),
	  width var(--transition);
  transition-timing-function: linear(
    0 0%, 0.2342 12.49%, 0.4374 24.99%,
    0.6093 37.49%, 0.6835 43.74%,
    0.7499 49.99%, 0.8086 56.25%,
    0.8593 62.5%, 0.9023 68.75%, 0.9375 75%,
    0.9648 81.25%, 0.9844 87.5%,
    0.9961 93.75%, 1 100%
  );
}

ul::after {
	top: calc(var(--target-y) * 1px);
	left: calc((var(--target-x) * 1px) + 1.25rem);
	height: calc(var(--target-height) * 1px);
	width: calc((var(--target-width) * 1px) - 2.5rem);
	opacity: 1;
  /* background: #f3f3f3; */
  border: none;
	border-radius: 10px;
  border: 1px solid #dbdbdb;


}

/* Targeting */
@supports(anchor-name: --a) {
	nav { --anchor: var(--item-active); }
	
	nav li:nth-of-type(1) { anchor-name: --item-1; }
	nav li:nth-of-type(2) { anchor-name: --item-2; }
	nav li:nth-of-type(3) { anchor-name: --item-3; }
	nav li:nth-of-type(4) { anchor-name: --item-4; }
	nav li:nth-of-type(5) { anchor-name: --item-5; }
	nav li:nth-of-type(6) { anchor-name: --item-6; }


	nav:has(li:nth-of-type(1) a:is(:hover, :focus-visible)) { --anchor: --item-1; }
	nav:has(li:nth-of-type(2) a:is(:hover, :focus-visible)) { --anchor: --item-2; }
	nav:has(li:nth-of-type(3) a:is(:hover, :focus-visible)) { --anchor: --item-3; }
	nav:has(li:nth-of-type(4) a:is(:hover, :focus-visible)) { --anchor: --item-4; }
	nav:has(li:nth-of-type(5) a:is(:hover, :focus-visible)) { --anchor: --item-5; }
	nav:has(li:nth-of-type(6) a:is(:hover, :focus-visible)) { --anchor: --item-6; }

	nav:has(li:nth-of-type(1) a:target) { --target: --item-1; }
	nav:has(li:nth-of-type(2) a:target) { --target: --item-2; }
	nav:has(li:nth-of-type(3) a:target) { --target: --item-3; }
	nav:has(li:nth-of-type(4) a:target) { --target: --item-4; }
	nav:has(li:nth-of-type(5) a:target) { --target: --item-5; }
	nav:has(li:nth-of-type(6) a:target) { --target: --item-6; }


	ul::before {
		left: calc(anchor(var(--anchor) left) + 1.25rem);
		top: anchor(var(--anchor) top);
		width: calc(anchor-size(var(--anchor) width) - 2.5rem);
		height: anchor-size(var(--anchor) height);
    /* background: #f3f3f3; */
	}

	ul::after {
		left: calc(anchor(var(--target) left) + 1.25rem);
		top: anchor(var(--target) top);
		width: calc(anchor-size(var(--target) width) - 2.5rem);
		height: anchor-size(var(--target) height);
    background: #f3f3f3;
	}
}

::view-transition-group(target),
::view-transition-group(item) {
	mix-blend-mode: normal;
}





button svg {
	width: 55%
}




.socials{
  margin-top: 10px;
  margin-bottom: 60px;
  
}




.main{
  width: 100%;
  margin-left: 0%;

}

@media (max-width: 768px) {
  ul::before {
    background: #f3f3f300;
    border: none;
  }
  ul::after {
    background: #f3f3f300;
    border: none;
  }
  html{
    overflow: hidden;
  }
  .socials{
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .main{
    width: 90vw;
    margin-left: 5vw;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    align-items: top;
    justify-content: top;
    height: 100vh;
  }
  body{
    width: 100%;
    margin-left: 0%;
    /* background-color: aqua; */
    display: flex;
    padding: 0;

    justify-content: left;
  }
}